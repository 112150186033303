import React, {Component} from "react"
import styled from "styled-components"
import DA from "./DeviceAnimation"
import Swal from 'sweetalert2'
import emailjs from 'emailjs-com'
import {CookieNotice} from 'react-cookienotice'
import 'react-cookienotice/dist/style.css'

const Title = styled.h1`
  font-weight: 300;
  font-size: 60px;
    line-height: 72px;
  
  @media screen and (max-width: 700px) {
      font-size: 42px;
      font-weight: 400;
  }
  
  i {
    font-style: normal;
    font-weight: 900;
    background-color: #3b97d3;
    background-image: linear-gradient(45deg,#33aaff,#3bd378);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const Header = styled.div`
  width: 100%;
  padding-bottom: 128px;
  background: white;
  clip-path: circle(55.0% at 50% 50%);
  user-select: none;

  @media screen and (max-width: 1700px) {
      clip-path: none;
  }
  
  nav {
    padding-top: 96px;

    .container {
      width: 100%;
      padding: 0 64px;
      margin: 0 auto;
      max-width: 1280px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      
      @media screen and (max-width: 1400px) {
          box-sizing: border-box;
        
          ul, .right {
              display: none !important;
          }
      }

      button {
        margin-top: 0;
        height: 48px;
        font-size: 18px;
        padding: 0 32px;
      }

      .telephone {
        margin-right: 32px;
        font-weight: 600;
        color: #3b97d3;
        text-decoration: none;
        
        i {
          margin-right: 8px;
        }
      }

      .right {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
      }
      
      ul {
        li {
          list-style-type: none;
          display: inline-flex;
          margin-right: 16px;
          cursor: pointer;
          padding: 0 14px;
          transition: all 0.3s;
          border-radius: 5px;
          height: 44px;
          align-items: center;
          color: rgba(0, 0, 0, 0.75);
          
          &:hover {
              background: #3b97d3;
              color: white;
          }
          
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    img {
      height: 48px;
      
      @media screen and (max-width: 1024px) {
          height: 40px;
      }
    }
  }

  button {
    outline: 0;
    background: #3b97d3;
    //background: linear-gradient(-45deg,#004dbf,#00e1cc);
    color: white;
    width: min-content;
    border: none;
    height: 64px;
    white-space: nowrap;
    padding: 0 32px;
    border-radius: 4px;
    margin-top: 24px;
    font-size: 22px;
    //box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.45);
    font-weight: 400;
    font-family: inherit;
    cursor: pointer;
    transition: all 0.3s;
    
      &:hover {
          background: #3acc8e;
      }
  }



  .telephoneUnder {
    text-decoration: none;
    color: #3b97d3;
    margin-top: 24px;
    font-size: 18px;
    display: none;

    @media screen and (max-width: 1400px) {
      display: block;
    }
  }

  .container {
    width: 100%;
    padding: 0 64px;
    max-width: 1280px;
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-gap: 96px;
    margin: 80px auto 0;

    @media screen and (max-width: 1400px) {
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 1400px) {
      box-sizing: border-box;
    }
    
    .right {
      display: flex;
      justify-content: center;
      perspective: 1500px;
      flex-direction: column;
      
      @media screen and (max-width: 1400px) {
          display: none;
      }

      img {
        transform: rotate3d(0.5, -0.8, 0, 20deg);
        width: 100%;
        object-fit: cover;
        height: 80%;
        border-radius: 15px;
        overflow: hidden;
      }
    }

    .left {
      display: flex;
      justify-content: center;
      flex-direction: column;
      
      .flag {
        height: 20px;
        width: 32px;
        margin-right: 8px;
        overflow: hidden;
        border-radius: 3px;
      }
      .stripe {
        display: block;
        width: 100%;
        height: 33.3%;
      }
      .black {
        background-color: #000000;
      }
      .red {
        background-color: #FF0000;
      }
      .gold{
        background-color: #FFCC00;
      }
      
      ul {
        margin: 8px 0;
        margin-bottom: 16px;

        li {
          vertical-align: middle;
          display: inline-flex;
          align-items: center;
          list-style-type: none;
          font-weight: 400;
          margin-right: 24px;
          font-size: 18px;

          @media screen and (max-width: 768px) {
            width: 100%;
            margin-bottom: 12px;
            
            i {
                width: 20px;
            }
            
            &:nth-child(3) {
                margin-bottom: 0;
            }
          }
          
          i {
            margin-right: 8px;
            color: #3ace88;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
`

const Subtitle = styled.h2`
    font-weight: 300;
    opacity: 0.75;
    line-height: 48px;
    font-size: 26px;
    margin: 24px 0;
`

const WorkSections = styled.div`
  padding: 128px 0;
  
  .container {
    width: 100%;
    padding: 0 64px;
    max-width: 1280px;
    margin: 0 auto;

    @media screen and (max-width: 1400px) {
      box-sizing: border-box;
    }
  }
  
  .grid {
      background: white;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 48px;
      border-radius: 10px;
      padding: 48px;
      box-shadow: 0 4px 24px 0 rgb(0 0 0 / 10%);
    
      @media screen and (max-width: 1024px) {
          grid-template-columns: 1fr 1fr;
      }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      
      .workSection {
        padding: 16px 0 !important;
      }
    }
  }
  
  .andMore {
      text-align: center;
      margin-top: 64px;
      font-size: 20px;
      opacity: 0.75;
  }

  h2 {
    font-size: 48px;
    font-weight: 900;
    background-color: #3b97d3;
    background-image: linear-gradient(45deg,#33aaff,#00ff7e,#00ff7e);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    user-select: none;
    -webkit-text-fill-color: transparent;
  }

  h3 {
    margin-bottom: 64px;
    font-size: 24px;
    font-weight: 300;
    opacity: 0.75;
    user-select: none;
    margin-top: 8px;
  }
  
  .workSection {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 48px 0;
    
      i {
          font-size: 64px;
          color: #3b97d3;
        user-select: none;
      }
    
      p {
          font-size: 20px;
          margin-top: 32px;
          user-select: none;
          font-weight: 400;
      }
  }
`

export default class extends Component {
    state = { privacyChecked: false, name: null, phone: null, email: null, company: null, message: null }

    render() {
        return <>
            <FeatureModal onRef={ref => this.featureInvoices = ref} title="Rechnungswesen" subtitle="Mit unseren einfachen Rechnungstools verwaltest du deine Faktura kinderleicht - und verlierst dabei nie den Überblick." features={[
                "Rechnungen schreiben & verschicken",
                "Mahnungen schreiben & verschicken",
                "Gutschriften schreiben & verschicken",
                "Offene Posten einsehen und summieren (Umsatz oder Ertrag)",
                "Sammelgruppen erstellen, um viele Aufträge summiert abzurechnen",
                "Teil- & Abschlagsrechnungen generieren",
                "Rechnungen in Sekundenschnelle aus Aufträgen generieren",
                "Schnittstelle zu Factoringanbietern"
            ]} img="/feature_invoices.svg" />
            <FeatureModal onRef={ref => this.featureTours = ref} title="Tourenplanung" subtitle="Mit unserem einmaligen Planungstool „Masterplan“ erstellst du Routen-optimierte Touren, um Fahrtkosten zu sparen und wertvolle Arbeitszeit zu gewinnen." features={[
                "Touren auf Basis von Fälligkeiten automatisch disponieren",
                "Fahrtstrecken auf KI-Basis automatisch optimieren lassen",
                "Kundeneinsätze Ressourcen-unabhängig vorbereiten",
                "Aufträge standortbasiert mit Priorisierung einplanen",
                "Automatisch Monteure vorschlagen auf Basis der kürzesten Anfahrt",
                "Auftragsabarbeitung chronologisch definieren"
            ]} img="/feature_tours.svg" />
            <FeatureModal onRef={ref => this.featureSettings = ref} title="Stammdaten" subtitle="Die interne Stammdatenverwaltung ermöglicht die Pflege und Verwaltung von gängigen Datensätzen." features={[
                "Hersteller anlegen & verwalten",
                "Lieferanten anlegen & verwalten",
                "Mangelvorlagen anlegen & verwalten",
                "Zulassungen anlegen & verwalten",
                "Komponenten anlegen & verwalten",
                "Prüfungen anlegen & verwalten",
                "Gewerke anlegen & verwalten",
                "Textvorlagen anlegen & verwalten"
            ]} img="/feature_settings.svg" />
            <FeatureModal onRef={ref => this.featureWorkOrders = ref} title="Auftragsabwicklung" subtitle="Prozessbasierte Auftragsabwicklung über alle Stufen vom Angebot bis zur Rechnung." features={[
                "Angebote schreiben & verschicken",
                "Auftragsbestätigungen schreiben & verschicken",
                "Lieferscheine schreiben & verschicken",
                "Leistungsnachweise generieren",
                "Wartungsprotokolle generieren",
                "Instandhaltungsberichte generieren",
                "Mit nur einem Mausklick einen Auftrag zu einer Rechnung wandeln",
                "Arbeitsaufträge erstellen und einem oder mehreren Monteuren zuweisen",
                "Erledigte Arbeit vor Ort transparent dokumentieren und abrechnungsbereit erfassen"
            ]} img="/feature_work_orders.svg" />
            <FeatureModal onRef={ref => this.featureCalendar = ref} title="Einsatzplanung" subtitle="Mit unserer Kalender-basierten Einsatzplanung teilst du deinen Monteure freie Timeslots zu, um vorher erstellte Touren einzuplanen." features={[
                "Monteure nach Kalendertagen zu Touren zuteilen",
                "Doppelbelegungen erkennen und beheben",
                "Urlaube & Krankheitstage automatisch berücksichtigen (iCal-Schnittstelle)",
                "Zuarbeiter definieren und gemeinsame Touren festlegen",
                "Auftragsdurchführung nachhalten und ggf. neu einsetzen",
                "Kunden in Sekundenschnelle Information darüber erteilen können, wann die Durchführung eines Auftrags zu erwarten ist"
            ]} img="/feature_calendar.svg" />
            <FeatureModal onRef={ref => this.featureCommodities = ref} title="Warenwirtschaft" subtitle="Unsere Warenwirtschaft erleichtert dir und deinen Monteuren den Arbeitsalltag mit zahlreichen, konsolidierten Tools." features={[
                "Artikelkatalog anlegen & pflegen",
                "Warengruppen definieren und Artikeln zuordnen",
                "Ein- und Verkaufspreise definieren und Lieferanten verknüpfen",
                "Kundenspezifische Preise hinterlegen",
                "Stammdaten bereits in Artikeln hinterlegen, um deinen Monteuren vor Ort Zeit zu sparen",
                "Lagerbestände erfassen und Materialbewegungen nachhalten",
                "Materialeinsatz bei Kunden dokumentieren",
                "Mindestbestandsmengen festlegen und benachrichtigt werden, wenn die Grenze unterschritten wurde"
            ]} img="/feature_commodities.svg" />
            <FeatureModal onRef={ref => this.featureExpenses = ref} title="Spesengeld" subtitle="Mit unseren Tools zur Spesenverwaltung behältst du deine Ausgaben stets im Blick." features={[
                "scanmetrix-eigene VISA-Debitkarten bestellen (10 EUR pro Monat pro Karte)",
                "Deinen Monteuren Guthaben zur Verfügung stellen, um bspw. Hotelrechnungen vor Ort zu begleichen",
                "Spesenauszahlungen im Blick behalten und auf Tourenbasis kalkulieren lassen",
                "Eingangsbelege mobil erfassen und Ausgaben zuordnen"
            ]} img="/feature_expenses.svg" />
            <FeatureModal onRef={ref => this.featurePlugins = ref} title="Flexible Schnittstellen" subtitle="scanmetrix bietet zahlreiche Schnittstellen und Plugins an, um unser System nahtlos an deine IT anzubinden." features={[
                "Diverse Partnerschaften mit namreichen SaaS-Anbietern",
                "Offene Schnittstelle als REST-API oder per GraphQL",
                "Zahlreiche Verbindungen zu bspw. LexOffice, Stripe, FinAPI uvm.",
                "Die Möglichkeit, deine eigenen Systeme anzubinden"
            ]} img="/feature_plugins.svg" />
            <FeatureModal onRef={ref => this.featureFieldService = ref} title="Mobiler Monteur" subtitle="Mit unserer mobilen App (scanmetrix Field Service) für iOS und Android stellen wir deinen Monteuren eine in der Handhabung kinderleichte App zur Verfügung, um die vor Ort noch effizienter und transparenter zu gestalten." features={[
                "100% offlinefähig, damit auch in netzschwachen Regionen gearbeitet werden kann",
                "Objekte auf QR-Code Basis aufnehmen und durchgeführte Arbeiten dokumentieren",
                "Arbeitszeiten & Fahrten erfassen",
                "Materialeinsatz dokumentieren",
                "Auftragsdurchführung vom Kunden vor Ort gegenzeichnen lassen",
                "Spesenbelege fotografieren und hochladen"
            ]} img="/feature_mobile.svg" />
            <CookieNotice descriptionLabel='Wenn Sie auf "Akzeptieren" klicken, erklären Sie sich damit einverstanden, dass unsere Website Cookies verwendet, um Ihnen die bestmögliche Erfahrung zu bieten, indem Ihre Cookie-Einstellungen gespeichert werden.' declineButtonLabel="Ablehnen" cookieTextLabel="Diese Webseite benutzt Cookies um ein optimales Nutzungserlebnis zu garantieren." acceptButtonLabel="Akzeptieren" readMoreButtonLabel="Mehr erfahren" />
            <div style={{ background: "linear-gradient(45deg, #3b97d3, #3acd8c)", position: "relative", zIndex: 2, boxShadow: "0 8px 14px 0 rgba(0, 0, 0, 0.05)" }}>
            <Header>
                <nav>
                    <div className="container">
                        <img src="/logo_text_black.svg" alt="Unser Logo auf hellem Hintergrund" />
                        <ul>
                            <li onClick={() => this.featuresRef.scrollIntoView({ behaviour: "smooth", block: "start" })}>Funktionen</li>
                            <li onClick={() => this.pricingRef.scrollIntoView({ behaviour: "smooth", block: "start" })}>Preise</li>
                            <li onClick={() => window.open("https://betreiber.scanmetrix.io", "_blank")}>Für Betreiber</li>
                        </ul>
                        <div className="right">
                            <a href="tel:+4920898969000" className="telephone"><i className="fas fa-phone" /> 0208 - 98 96 9000</a>
                            <button onClick={() => this.contactRef.scrollIntoView({ behaviour: "smooth", block: "start" })}>Demo anfragen</button>
                        </div>
                    </div>
                </nav>
                <div className="container">
                    <div className="left">
                        <Title>Die <i>Handwerkscloud</i> für das digitale Zeitalter</Title>
                        <Subtitle>
                            scanmetrix digitalisiert deinen Handwerksbetrieb - von der Aufnahme vor Ort bis zur Rechnungsstellung.
                        </Subtitle>
                        <ul className="usps">
                            <li><div className="flag">
                                <span className="black stripe"></span>
                                <span className="red stripe"></span>
                                <span className="gold stripe"></span>
                            </div> Made in Germany</li>
                            <li><i className="fas fa-file-invoice" /> GoBD-konform</li>
                            <li><i className="fas fa-badge-check" /> DSGVO-konform</li>
                        </ul>
                        <button onClick={() => this.contactRef.scrollIntoView({ behaviour: "smooth", block: "start" })}>Kostenlose Demo anfragen</button>
                        <a href="tel:+4920898969000" className="telephoneUnder">oder telefonisch unter <i className="fas fa-phone" /> 0208 - 98 96 9000</a>
                    </div>
                    <div className="right">
                        <img src="/tablet.jpg" alt="Handwerker hält dein Tablet in der Hand" />
                    </div>
                </div>
            </Header>

        </div>
            <WorkSections>
                <div className="container">
                    <h2>Zielgruppen</h2>
                    <h3>scanmetrix richtet sich an Handwerksbetriebe, die Servicedienstleistungen wie Wartungen oder Instandhaltungen vornehmen - dazu gehören insbesondere die folgenden Gewerke.</h3>
                    <div className="grid">
                        <div className="workSection">
                            <i className="fad fa-fire-extinguisher" />
                            <p>Brandschutz</p>
                        </div>
                        <div className="workSection">
                            <i className="fad fa-air-conditioner" />
                            <p>Kälte-/Klimatechnik</p>
                        </div>
                        <div className="workSection">
                            <i className="fad fa-hose" />
                            <p>Löschwassertechnik</p>
                        </div>
                        <div className="workSection">
                            <i className="fad fa-wind" />
                            <p>Lüftungstechnik</p>
                        </div>
                        <div className="workSection">
                            <i className="fad fa-plug" />
                            <p>Elektrik & Meldezentralen</p>
                        </div>
                        <div className="workSection">
                            <i className="fad fa-bolt" />
                            <p>Blitzschutz</p>
                        </div>
                        <div className="workSection">
                            <i className="fad fa-heat" />
                            <p>Heiztechnik</p>
                        </div>
                        <div className="workSection">
                            <i className="fad fa-escalator" />
                            <p>Personenbeförderungsanlagen</p>
                        </div>
                        <div className="workSection">
                            <i className="fad fa-pipe-circle-check" />
                            <p>Sanitäranlagen</p>
                        </div>
                    </div>
                    <div className="andMore">...und alle Handwerksbetriebe, die zyklische Dienstleistungen bei ihren Kunden erbringen.</div>
                </div>
            </WorkSections>
            <DA />
            <Features ref={ref => this.featuresRef = ref}>
                <div className="container">
                    <h2>Diese Funktionen warten auf dich</h2>
                    <h3>Nur einige der zahlreichen Funktionen, die scanmetrix zu bieten hat.</h3>
                    <div className="features">
                        <div className="feature">
                            <div className="icon">
                                <i className="far fa-file-invoice" />
                            </div>
                            <div className="right">
                                <div className="title">Rechnungswesen</div>
                                <div className="subtitle">Rechnungen, Mahnungen, Gutschriften uvm. schreiben & erstellen</div>
                                <div className="learn" onClick={() => this.featureInvoices.open()}>Mehr erfahren <i className="far fa-arrow-right" /></div>
                            </div>
                        </div>

                        <div className="feature">
                            <div className="icon">
                                <i className="far fa-map-marker" />
                            </div>
                            <div className="right">
                                <div className="title">Tourenplanung</div>
                                <div className="subtitle">Fahrtwege optimieren, Routen generieren und potenzielle Aufträge disponieren</div>
                                <div className="learn" onClick={() => this.featureTours.open()}>Mehr erfahren <i className="far fa-arrow-right" /></div>
                            </div>
                        </div>
                            <div className="feature">
                                <div className="icon">
                                    <i className="far fa-database" />
                                </div>
                                <div className="right">
                                    <div className="title">Stammdaten</div>
                                    <div className="subtitle">Stammdatenerfassung- und Verwaltung für alle handwerklichen Gewerke</div>
                                    <div className="learn" onClick={() => this.featureSettings.open()}>Mehr erfahren <i className="far fa-arrow-right" /></div>
                                </div>
                            </div>
                        <div className="feature">
                            <div className="icon">
                                <i className="far fa-wrench" />
                            </div>
                            <div className="right">
                                <div className="title">Auftragsabwicklung</div>
                                <div className="subtitle">Angebote & Aufträge erstellen, nachhalten und Leistungsnachweise erzeugen</div>
                                <div className="learn" onClick={() => this.featureWorkOrders.open()}>Mehr erfahren <i className="far fa-arrow-right" /></div>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="icon">
                                <i className="far fa-project-diagram" />
                            </div>
                            <div className="right">
                                <div className="title">Einsatzplanung</div>
                                <div className="subtitle">Ressourcen & Zeiteinteilung deiner Monteure planen und Umsatz berechnen</div>
                                <div className="learn" onClick={() => this.featureCalendar.open()}>Mehr erfahren <i className="far fa-arrow-right" /></div>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="icon">
                                <i className="far fa-warehouse" />
                            </div>
                            <div className="right">
                                <div className="title">Warenwirtschaft</div>
                                <div className="subtitle">Warengruppen, Preise, Artikel und dessen Lager- & Mindestbestände erfassen</div>
                                <div className="learn" onClick={() => this.featureCommodities.open()}>Mehr erfahren <i className="far fa-arrow-right" /></div>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="icon">
                                <i className="far fa-credit-card" />
                            </div>
                            <div className="right">
                                <div className="title">Spesengeld</div>
                                <div className="subtitle">Automatische Spesenabrechnungen durch scanmetrix Debitkarten (VISA)</div>
                                <div className="learn" onClick={() => this.featureExpenses.open()}>Mehr erfahren <i className="far fa-arrow-right" /></div>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="icon">
                                <i className="far fa-puzzle-piece" />
                            </div>
                            <div className="right">
                                <div className="title">Flexible Schnittstellen</div>
                                <div className="subtitle">U. a. Schnittstellen zu deiner Buchhaltung, Stammdatenverwaltung und Bankkonto</div>
                                <div className="learn" onClick={() => this.featurePlugins.open()}>Mehr erfahren <i className="far fa-arrow-right" /></div>
                            </div>
                        </div>
                        <div className="feature">
                            <div className="icon">
                                <i className="far fa-scanner-touchscreen" />
                            </div>
                            <div className="right">
                                <div className="title">Mobiler Monteur</div>
                                <div className="subtitle">Das mobile Handwerksbüro für unterwegs, 100% offline-fähig (<i className="fab fa-android" /> Android / <i className="fab fa-apple" /> iOS)</div>
                                <div className="learn" onClick={() => this.featureFieldService.open()}>Mehr erfahren <i className="far fa-arrow-right" /></div>
                            </div>
                        </div>
                    </div>
                    <button onClick={() => this.contactRef.scrollIntoView({ behaviour: "smooth", block: "start" })}>Alle Funktionen entdecken</button>
                </div>
            </Features>

            {/*<DeviceWrapper />*/}
            <CallToAction>
                <div className="container">
                    <div className="background" />
                    <div className="content">
                        <div className="left">
                            <img src="/devices.png" alt="Screenshots der Software auf einem Laptop und einem Tablet" />
                        </div>
                        <div className="right">
                            <h3>Nie wieder um Hardware kümmern. Wirklich.</h3>
                            <p>Da scanmetrix eine Cloudlösung ist, musst du dich nie wieder um Installationen, Updates oder Hardwarebeschaffung kümmern. Benutze einfach deine bereits vorhandenen Geräte - <b>völlig ohne Installation.</b></p>
                        </div>
                    </div>
                </div>
            </CallToAction>
            <USPS>
                <div className="container">
                    <h2>Unsere Prinzipien</h2>
                    <h3>Dafür steht das Team hinter scanmetrix.</h3>
                    <div className="usps">
                        <div className="usp">
                            <i className="fas fa-browser" />
                            <p className="title">Wir <i className="far fa-heart" /> Kompatibilität</p>
                            <p className="description">
                                Der scanmetrix Innendienst ist mit jedem modernen Webbrowser kompatibel (Google Chrome, Microsoft Edge, Mozilla Firefox etc.). Der Außendienst kann in Form einer nativen App unter iOS (nur iPads) und Android (nur Tablets) verwendet werden. Updates geschehen völlig automatisch im Hintergrund.
                            </p>
                        </div>
                        <div className="usp">
                            <i className="fas fa-blinds" />
                            <p className="title">Wir <i className="far fa-heart" /> Datenschutz</p>
                            <p className="description">
                                Datenschutz wird bei uns <b>ganz groß</b> geschrieben. Alle unsere Produkte sind 100% DSGVO-konform und halten sich an alle maßgeblichen Datenschutznormen. Die dabei verwendeten Technologien sind State-of-the-art, um das bestmögliche Qualitätsniveau unserer Datensicherungssysteme zu gewährleisten.
                            </p>
                        </div>
                        <div className="usp">
                            <i className="fas fa-code" />
                            <p className="title">Wir <i className="far fa-heart" /> Entwickler</p>
                            <p className="description">
                                Du möchtest scanmetrix an deine bestehenden Systeme anbinden oder bist selbst Softwareanbieter, der eine Schnittstelle zu uns anbieten möchte? Kein Problem! scanmetrix stellt eine öffentliche REST-API bereit, mit welcher alle Funktionen unseres Produkts angesteuert und ausgelesen werden können.
                            </p>
                        </div>
                    </div>
                </div>
            </USPS>
        <Pricing ref={ref => this.pricingRef = ref}>
            <div className="container">
                <div className="left">
                    <i className="fas fa-check-double" />
                    <h3>
                        Beginne noch <b>heute</b>, deinen Handwerksbetrieb zu digitalisieren.
                    </h3>
                    <h4>
                        Die Organisation deines Betriebs beginnt im Innendienst - daher hilft scanmetrix dir mit starken Planungstools, prozessbasierter Auftragsabwicklung und automatisierter Rechnungsstellung bei deinem Tagesgeschäft.
                    </h4>
                </div>
                <div className="right">
                    <div className="pricing onsite">
                        {/*<div className="discount"><i>🎉</i> Osterrabatt bis 31.05.2022</div>*/}
                        <p className="title">Außendienst</p>
                        <div className="price">
                            <p className="currency"><i className="fas fa-euro-sign" /></p>
                            <p className="amount">79</p>
                            <p className="type">pro Monat{/*<br /><i>anstatt 79 €</i>*/}</p>
                        </div>
                        <p className="description">Pro Monteur im Außendienst, der ein mobiles Endgerät verwendet (<i className="fab fa-android" /> Android / <i className="fab fa-apple" /> iOS)</p>
                        <ul>
                            <li><i className="fas fa-check" /> Mobile Datenaufnahme</li>
                            <li><i className="fas fa-check" /> QR-Code-basierte Wartung</li>
                            <li><i className="fas fa-check" /> Kaufm. Aufwandserfassung</li>
                            <li><i className="fas fa-check" /> Zeiterfassung</li>
                        </ul>
                        <button onClick={() => this.contactRef.scrollIntoView({ behaviour: "smooth", block: "start" })}>Demo anfragen</button>
                    </div>
                    <div className="pricing office">
                        <p className="title">Innendienst</p>
                        <div className="price">
                            <p className="currency">€</p>
                            <p className="amount">99</p>
                            <p className="type">pro Monat</p>
                        </div>
                        <p className="description">Pro Mitarbeiter im Innendienst, der einen Browser verwendet (bspw. <i className="fab fa-chrome" /> Chrome, <i className="fab fa-firefox" /> Firefox)</p>
                        <ul>
                            <li><i className="fas fa-check" /> Digitales Handwerkerbüro</li>
                            <li><i className="fas fa-check" /> Planung & Auswertung</li>
                            <li><i className="fas fa-check" /> Ressourcenmanagement</li>
                            <li><i className="fas fa-check" /> Auftragsabwicklung</li>
                        </ul>
                        <button onClick={() => this.contactRef.scrollIntoView({ behaviour: "smooth", block: "start" })}>Demo anfragen</button>
                    </div>
                    <p className="bottomText">zzgl. 299€ pro Monat Grundgebühr</p>
                </div>
            </div>
        </Pricing>
            <div style={{ background: "#20242b" }}>
                <FAQ>
                    <div className="container">
                        <h2>FAQ - Häufige Fragen</h2>
                        <h3>Du kannst deine Frage nicht finden? Kontaktiere uns gern!</h3>
                        <FAQItem title="Wie erhalte ich Zugriff auf scanmetrix?">
                            Du erhältst scanmetrix im Rahmen eines sogenannten <b>Onboarding-Prozesses</b>. Folgende Schritte kommen auf dich zu:
                            <ul style={{ marginTop: 16, listStyleType: "decimal" }}>
                                <li>Du baust Kontakt zu uns über das Kontaktformular oder telefonisch auf.</li>
                                <li>Wir melden uns umgehend bei dir, um einen Präsentations- und Kennenlerntermin zu vereinbaren.</li>
                                <li>Im Anschluss des Termins erhältst eine völlig kostenfreie Demoversion, die du 30 Tage lang auf Herz und Nieren testen kannst. In diesem Zeitraum betreuen wir dich in der Handhabung der Software.</li>
                                <li>Zum Ende der Demoversion, oder durch vorzeitige Beendigung durch dich, setzen wir uns mit dir in Verbindung und erstellen dir ein auf deine Unternehmensgröße angepasstes Angebot (Preise wie oben beschrieben).</li>
                                <li>Sagt dir unser Produkt zu, klären wir umgehend den Papierkram und vereinbaren einen gemeinsamen Onboarding-Termin.</li>
                                <li>Du kannst durchstarten <i className="far fa-rocket-launch" /></li>
                            </ul>
                        </FAQItem>
                        <FAQItem title="Welchen Mehrwert bietet scanmetrix gegenüber anderen Handwerkersoftwares?">
                            <ul>
                                <li>Bei scanmetrix handelt es sich um ein sehr neues Produkt, weshalb nur State-of-the-art Technologien eingesetzt werden</li>
                                <li>Die Software kann geräteunabhängig verwendet werden</li>
                                <li>Die Software verfügt über eine sehr einfache Benutzeroberfläche, mit der du dich von Tag eins zurechtfindest</li>
                                <li>Die Software bietet eine öffentliche API sowie diverse Schnittstellen zu gängigen Softwarelösungen und ermöglicht damit eine nahtlose Integration in deine IT</li>
                                <li>Wir sind selbst ein Handwerksbetrieb und kennen die täglichen Probleme und Zwiespalte daher nur zu gut; das macht sich in der Umsetzung der Software bemerkbar</li>
                                <li>scanmetrix bietet einen eigenen Objektlassen- und Prüfungseditor an, weshalb wir unsere Software an jede Art von Handwerksgewerk anbieten können - völlig ohne Einschränkungen in der Datengranularität</li>
                            </ul>
                        </FAQItem>
                        <FAQItem title="Woher erhalte ich die erwähnten QR-Code Sticker für die Objektaufnahme?">
                            Die QR-Code Sticker für die Objektaufnahme sind optional. Jedoch raten wir dir dazu, diese einzusetzen - das erleichtert nicht nur deinem Kunden die Zuordnung der Objekte, sondern auch deinen Monteuren, wenn sie den Kunden anfahren. Die QR-Code Sticker werden bei uns im Haus hergestellt und sind käuflich erwerbbar. Alle Details dazu klären wir selbstverständlich im Rahmen deines Onboardings.
                        </FAQItem>
                        <FAQItem title="Für welche Unternehmensgrößen ist scanmetrix geeignet?">
                            scanmetrix ist für den Einsatz in KMU konzipiert. Egal ob du nur einen oder 500 Monteure hast - unsere Cloud skaliert automatisch mit deinen Bedürfnissen. Gerne besprechen wir individuelle Anpassungen an deine Unternehmensstruktur (wie z.B. Mehrmandantenfähigkeit oder BI-Anbindung) in einem persönlichen Gespräch.
                        </FAQItem>
                        <FAQItem title="Kann ich meine bestehenden Daten in scanmetrix importieren?">
                            Grundsätzlich ist es möglich, Daten jeder Art zu importieren. Dafür benötigen wir vorab von dir den Aufbau deiner bestehenden Datenstruktur (z.B. als CSV, Excel oder Schnittstelle) um die Details mit dir zu klären. Darüberhinaus bietet scanmetrix von Haus aus diverse Schnittstellen zu gängigen Dateiformaten, um bspw. den Import deiner Warenwirtschaft zu erleichtern - ganz ohne unser Zutun.
                        </FAQItem>
                        <FAQItem title="Für wen ist scanmetrix geeignet?">
                            scanmetrix richtet sich hauptsächlich an KMU, die folgende Voraussetzungen erfüllen:
                            <ul>
                                <li>Du beschäftigst mindestens vier Mitarbeiter im Außendienst</li>
                                <li>Du beschäftigst mindestens einen Mitarbeiter im Innendienst</li>
                                <li>Dein Betrieb ist in der Instandhaltungs-/Wartungsbranche aktiv (zyklische Leistungserbringung, bspw. Brandschutz/Kälte-Klima/Heizung/Elektroarbeiten)</li>
                                <li>Du arbeitest primär für Gewerbekunden in dessen Objekten</li>
                            </ul>
                        </FAQItem>
                    </div>
                </FAQ>
            </div>
            <Contact ref={ref => this.contactRef = ref}>
                <div className="container">
                    <h2>Kontaktiere uns</h2>
                    <h3>Vereinbare eine Demoversion oder ein kurzes Webinar, um scanmetrix näher kennenzulernen.</h3>
                    <div className="content">
                        <div className="left">
                            <div className="inputs">
                                <Input label="Dein Name" value={this.state.name} onChange={name => this.setState({ name })} required />
                                <Input label="Telefonnr." value={this.state.phone} onChange={phone => this.setState({ phone })} />
                                <Input label="E-Mail-Adresse" value={this.state.email} onChange={email => this.setState({ email })} required />
                                <Input label="Firma" value={this.state.company} onChange={company => this.setState({ company })} />
                                <div className="text">
                                    <Input label="Deine Nachricht an uns" textArea value={this.state.message} required onChange={message => this.setState({ message })} />
                                </div>
                            </div>
                            <div className="privacy">
                                <Checkbox checked={this.state.privacyChecked} toggle={() => this.setState({ privacyChecked: !this.state.privacyChecked })} label={<>Ich habe den <a href="https://scanmetrix.fm/privacy-policy" target="_blank">Datenschutzhinweis</a> der scanmetrix.fm GmbH gelesen und stimme der Weiterverarbeitung meiner Daten für diese Kontaktaufnahme zu.</>} />
                            </div>
                            <button onClick={() => {
                                if(this.state.privacyChecked) {
                                    let errors = []

                                    if(!this.state.name || (this.state.name && this.state.name.trim().length === 0)) errors.push("name")
                                    if(!this.state.email || (this.state.email && this.state.email.trim().length === 0)) errors.push("email")
                                    if(!this.state.message || (this.state.message && this.state.message.trim().length === 0)) errors.push("message")

                                    if(errors.length === 0) {
                                        if(this.state.email.toLowerCase()
                                            .match(
                                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                            )) {

                                            Swal.fire({
                                                title: "Kontaktanfrage erfolgreich!",
                                                html: "Deine Kontaktanfrage wurde erfolgreich an uns übermittelt. Du erhältst in Kürze eine Bestätigungsmail.<br /><br />Wir werden uns schnellstmöglich mit dir in Verbindung setzen.",
                                                icon: "success",
                                                didOpen: () => {
                                                    Swal.showLoading()
                                                },
                                                confirmButtonText: "Verstanden"
                                            })

                                            emailjs.send("service_0kxwk21", "template_ogxdhhu", {
                                                name: this.state.name,
                                                phone: this.state.phone,
                                                company: this.state.company,
                                                email: this.state.email,
                                                message: this.state.message
                                            }, "user_x8940LKO2DVMwnbhkeQXQ").then(data => {
                                                Swal.hideLoading()

                                                if(data.status === 200) {
                                                    this.setState({ name: null, phone: null, email: null, company: null, message: null, privacyChecked: false })
                                                } else {
                                                    Swal.fire({
                                                        title: "Fehlgeschlagen.",
                                                        text: "Beim Versenden der Nachricht ist ein unbekannter Fehler aufgetreten. Bitte versuche es später erneut oder nutze eine unserer anderen Kontaktmöglichkeiten.",
                                                        icon: "warning",
                                                        confirmButtonText: "Verstanden"
                                                    })
                                                }
                                            })
                                        } else {
                                            Swal.fire({
                                                title: "Fehlgeschlagen.",
                                                text: "Bitte überprüfe deine E-Mail-Adresse auf Richtigkeit.",
                                                icon: "warning",
                                                confirmButtonText: "Verstanden"
                                            })
                                        }
                                    } else {
                                        Swal.fire({
                                            title: "Fehlgeschlagen.",
                                            html: "Bitte überprüfe deine Eingaben und stelle sicher, dass " + (errors.length > 1 ? "folgende Felder ausgefüllt sind" : "folgendes Feld ausgefüllt ist") + ":<br/><br/><ul>" + errors.map(error => ("<li>" + ({
                                                name: "Dein Name",
                                                email: "E-Mail-Adresse",
                                                message: "Deine Nachricht an uns"
                                            })[error] + "</li>")).join("") + "</ul>",
                                            icon: "warning",
                                            confirmButtonText: "Verstanden"
                                        })
                                    }
                                } else {
                                    Swal.fire({
                                        title: "Fehlgeschlagen.",
                                        text: "Bitte akzeptiere den Datenschutzhinweis, bevor du fortfährst.",
                                        icon: "warning",
                                        confirmButtonText: "Verstanden"
                                    })
                                }
                            }}><i className="far fa-paper-plane" /> Kontaktanfrage senden</button>
                        </div>
                        <div className="right">
                            <i className="fas fa-projector symbol" />
                            <h4>Du benötigst eine Präsentation?</h4>
                            <p className="text">Dir gefällt, was wir tun, jedoch musst du dein Team noch überzeugen? Kein Problem! Lade einfach unsere Präsentation in PDF-Form herunter.</p>
                            <div className="download" onClick={() => window.open("/Flyer%20Handwerk.pdf", "_blank")}><p><i className="fas fa-download" /> Software Flyer.pdf (1,27 MB)</p></div>
                            <div className="download" onClick={() => window.open("/Use%20Case%20Brandschutz.pdf", "_blank")}><p><i className="fas fa-download" /> Use Case Brandschutz.pdf (1,86 MB)</p></div>
                        </div>
                    </div>
                </div>
                <div className="background">
                    <div className="circleOuter">
                        <div className="circleInner">
                            <div className="planet" />
                        </div>
                    </div>
                </div>
            </Contact>
            <div style={{ background: "white" }}>
                <Footer>
                    <div className="container">
                        <div className="column">
                            <img className="logo" src="/logo_text_white.svg" alt="Unser Logo auf dunklem Hintergrund" />
                            <p className="description">Die scanmetrix.fm GmbH aus Mülheim an der Ruhr ist ein junges SaaS-Startup im Bereich der handwerklichen Digitalisierung. Mit ihrer gleichnamigen Software versucht sie, den Unternehmensalltag von Handwerkern verschiedenster Gewerke zu digitalisieren und ermöglicht damit den Gebäudebetreibern, ausgeführte Arbeiten und anstehende Prüfungen über ein Online-Portal transparent zu verfolgen und zu steuern.</p>
                        </div>
                        <div className="column">
                            <p className="title">Soziale Medien</p>
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/scanmetrix.fm/">Facebook <i className="fab fa-facebook" /></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/scanmetrixfm">Instagram <i className="fab fa-instagram" /></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/scanmetrix-fm-gmbh/">LinkedIn <i className="fab fa-linkedin" /></a>
                                </li>
                            </ul>
                        </div>
                        <div className="column">
                            <p className="title">Schnellverweise</p>
                            <ul>
                                <li>
                                    <a href="https://betreiber.scanmetrix.io">Für Betreiber</a>
                                </li>
                                <li>
                                    <a href="https://help.sp.scanmetrix.io">Hilfebereich</a>
                                </li>
                                <li>
                                    <a href="https://status.scanmetrix.io">Status & Störungen</a>
                                </li>
                                <li>
                                    <a href="https://scanmetrix.fm/privacy-policy">Datenschutz</a>
                                </li>
                                <li>
                                    <a href="https://scanmetrix.fm/imprint">Impressum</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="left">
                            Made with <i className="fas fa-heart" /> & hosted in Germany
                        </div>
                        <div className="right">
                            Alle angegebenen Preise verstehen sich zzgl. 19% Mehrwertsteuer.
                        </div>
                    </div>
                </Footer>
            </div>
        </>
    }
}

const USPS = styled.div`
    .container {
          width: 100%;
          padding: 0 64px;
          margin: 0 auto;
          max-width: 1280px;

      @media screen and (max-width: 1400px) {
          box-sizing: border-box;
      }

      h2 {
        font-size: 48px;
        font-weight: 900;
        user-select: none;
        color: #20242b;
      }

      h3 {
        margin-bottom: 64px;
        font-size: 24px;
        font-weight: 300;
        opacity: 0.75;
        user-select: none;
        margin-top: 8px;
      }

        .usps {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 64px;

          @media screen and (max-width: 1400px) {
              grid-template-columns: 1fr;
          }

          .usp {
            i.fas:first-child {
              font-size: 64px;
              margin-bottom: 32px;
              background-color: #3b97d3;
              background-image: linear-gradient(45deg,#33aaff,#00ff66);
              background-size: 100%;
              background-repeat: repeat;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            i.fa-heart {
              color: #e74c3c;
            }

            .title {
              margin-bottom: 16px;
              font-size: 24px;
              font-weight: 600;
            }

            .description {
              font-weight: 300;
              font-size: 18px;
              line-height: 32px;
            }
          }
        }
    }

    padding-bottom: 128px;
`

const StyledCheckbox = styled.div`
    display: flex;

    .checkbox {
      width: 24px;
      height: 24px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      flex-shrink: 0;
      margin-right: 16px;
      display: flex;
        align-items: center;
      justify-content: center;
      color: #3b97d3;
      cursor: pointer;
    }

    label {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.75);
      user-select: none;

      a {
          color: #3b97d3;
          text-decoration: none;
      }
    }
`

class Checkbox extends Component {
    render() {
        return <StyledCheckbox onClick={this.props.toggle}>
            <div className="checkbox">
                {this.props.checked && <i className="fas fa-check" />}
            </div>
            <label>{this.props.label}</label>
        </StyledCheckbox>
    }
}

const CallToAction = styled.div`
  margin-bottom: 128px;
  .container {
    width: 100%;
    padding: 0 64px;
    margin: 0 auto;
    max-width: 1280px;
    height: 512px;
    background: white;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;

    @media screen and (max-width: 1400px) {
        box-sizing: border-box;
        margin: 32px;
        width: calc(100% - 64px);
        height: fit-content;
    }

    .background {
        position: absolute;
        z-index: 1;
        width: 30%;
        height: 100%;
        border-radius: 0 100% 100% 0;
        left: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(45deg,#33aaff,#00ffa1);

        @media screen and (max-width: 1400px) {
            width: 100%;
            height: 200px;
            border-radius: 0 0 100% 100%;
        }
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 48px;
        z-index: 2;
        position: relative;
        user-select: none;

        @media screen and (max-width: 1400px) {
            grid-template-columns: 1fr;
            padding-bottom: 96px;
            grid-gap: 0;
        }

        .left {
            display: flex;
            height: 100%;
            align-items: center;

            img {
                height: 60%;
                margin-left: 64px;
                margin-top: 32px;
                -webkit-user-drag: none;
                max-width: 100%;

                @media screen and (max-width: 1400px) {
                    margin: 0 auto !important;
                    height: auto;
                    width: 420px;
                    margin-top: 96px !important;
                    margin-bottom: 48px !important;
                }
            }
        }

        .right {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            padding-right: 64px;

            @media screen and (max-width: 1400px) {
                text-align: center;
                padding-right: 0;
            }

            h3 {
                font-size: 40px;
                font-weight: 900;
            }

            p {
                margin-top: 16px;
                font-size: 22px;
                line-height: 36px;
                font-weight: 300;

                b {
                    color: #3acc8e;
                }
            }
        }
    }
  }
`

const Footer = styled.footer`
  background: #20242b;
  padding: 128px 0;
  color: white;
  border-radius: 96px 0 0 0;

  .copyright {
    width: 100%;
    padding: 0 64px;
    max-width: 1280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px auto 0;
    font-size: 14px;

    @media screen and (max-width: 1400px) {
      box-sizing: border-box;
    }

    @media screen and (max-width: 850px) {
        flex-wrap: wrap;
        justify-content: flex-start;

        .left, .right {
            flex-shrink: 0;
            width: 100%;
        }
    }

    .left {
        opacity: 0.75;
    }

    .right {
        opacity: 0.3;
    }

    i {
      color: #e74c3c;
    }
  }

  .container {
    width: 100%;
    padding: 0 64px;
    margin: 0 auto;
    max-width: 1280px;
    display: grid;
    grid-template-columns: 1.75fr 1fr 1fr;
    grid-gap: 64px;

    @media screen and (max-width: 1400px) {
        box-sizing: border-box;
    }

    @media screen and (max-width: 1080px) {
        grid-template-columns: 1fr 1fr;

        .column:first-child {
            grid-column-start: 1;
            grid-column-end: 3;

            img {
                width: 30%;
            }
        }

        .column * {
            text-align: left !important;

            i {
                margin-left: 10px;
            }
        }

        .column {
            align-items: flex-start !important;
        }
    }

    @media screen and (max-width: 800px) {
        img {
            width: 250px !important;
        }
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;

        .column:first-child {
            grid-column-end: 2;
        }
    }

    .column {
      text-align: right;

      &:first-child {
        text-align: left;
      }

      &:nth-child(2), &:nth-child(3) {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
      }

      img {
        width: 65%;
        margin-bottom: 16px;
      }

      ul {
        text-align: right;

        li {
          list-style-type: none;
          height: 40px;
          display: flex;
          align-items: center;
          text-align: right;
          font-size: 18px;

          a, p {
            width: 100%;
            text-align: right;
            opacity: 0.75;
            font-weight: 300;
            user-select: none;
            cursor: pointer;
            transition: all 0.3s;
            color: white;
            text-decoration: none;

            i {
              width: 32px;
              text-align: right;
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      p.description {
        font-size: 16px;
        line-height: 28px;
        font-weight: 300;
        opacity: 0.75;
      }

      .title {
        font-size: 24px;
        margin-bottom: 16px;
        font-weight: 900;
        background-color: #3b97d3;
        background-image: linear-gradient(45deg,#33aaff,#00ff95);
        background-size: 100%;rgb(59 151 211 / 11%)
        background-repeat: repeat;
        -webkit-background-clip: text;
        user-select: none;
        -webkit-text-fill-color: transparent;
        width: fit-content;
      }
    }
  }
`

const Contact = styled.div`
    background: white;
    width: 100%;
    padding: 128px 0;
    position: relative;
    overflow: hidden;
  border-radius: 96px 0 0 0;

    .privacy {
        margin-top: 16px;
    }

    .left .inputs {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: 1fr 1fr;

        .text {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;

            .text {
                grid-column-end: 2;
            }
        }
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 0.7fr;
        grid-gap: 80px;
        z-index: 2;
        position: relative;

        @media screen and (max-width: 980px) {
            grid-template-columns: 1fr;
        }

        .right {
            h4 {
                font-size: 28px;
                font-weight: 400;
            }

            p.text {
                font-weight: 300;
                line-height: 32px;
                margin-top: 16px;
                font-size: 18px;
            }

            i.symbol {
              font-size: 90px;
              margin-bottom: 32px;
              background-color: #3b97d3;
              background-image: linear-gradient(45deg,#33aaff,#00ff66);
              background-size: 100%;
              background-repeat: repeat;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .download {
                color: #3b97d3;
                margin-top: 16px;
                font-size: 18px;
                user-select: none;
                cursor: pointer;
                transition: opacity 0.3s;
                display: flex;
                align-items: center;

                b {
                    margin-left: 8px;
                }
              
                &:hover {
                    opacity: 0.75;
                }

                i {
                    margin-right: 8px;
                }
            }
        }
    }

  button {
    background: #3b97d3;
    color: white;
    outline: 0;
    border: none;
    height: 48px;
    padding: 0 24px;
    border-radius: 5px;
    font-size: 18px;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s;
    margin-top: 24px;

    i {
        margin-right: 10px;
    }

    &:hover {
      background: #3acc8e;
    }
  }

  h2 {
    font-size: 48px;
    font-weight: 900;
    user-select: none;
    color: #20242b;
  }

  h3 {
    margin-bottom: 64px;
    font-size: 24px;
    font-weight: 300;
    opacity: 0.75;
    user-select: none;
    margin-top: 8px;
    width: 50%;

    @media screen and (max-width: 980px) {
      width: 100%;
    }
  }

    .background {
        position: absolute;
        z-index: 1;
        width: 1024px;
        height: 1024px;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);

        .circleOuter {
            width: 100%;
            height: 100%;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .circleInner {
            width: 70%;
            height: 70%;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

      .planet {
        width: 70%;
        height: 70%;
        background: linear-gradient(45deg, rgb(59, 151, 211), rgb(58, 205, 140), rgb(58, 205, 140));
        border-radius: 100%;
      }
    }

  .container {
    width: 100%;
    padding: 0 64px;
    z-index: 2;
    margin: 0 auto;
    max-width: 1280px;
    position: relative;

    @media screen and (max-width: 1400px) {
        box-sizing: border-box;
    }
  }
`

/*
const currentFrame = index => (
    `/animation/ezgif-frame-${index.toString().padStart(3, '0')}.png`
)

class DeviceWrapper extends Component {
    state = { index: 1, images: [] }

    constructor(props) {
        super(props)

        this.handleScroll = this.handleScroll.bind(this)

        this.handleScroll(true)
    }

    handleScroll(isConstructor) {
        const frames = 41
        const speed = 600
        const offset = 900
        const index = Math.max(1, Math.min(Math.round((Math.max(window.pageYOffset - offset, 0) / speed) * frames), frames))

        if(isConstructor === true) this.state.index = index
        else this.setState({ index })
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)

        let images = []

        for(let i = 0; i < 41; i++) {
            const img = new Image()
            img.src = currentFrame(i + 1)

            images.push(img)
        }

        this.setState({ images })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    render() {
        return <video
            width="1280"
            muted
            preload="auto"
            playsInline
            autoPlay={false}
            ref={ref => this.videoRef = ref}
        >
            <source type="video/mp4" src="/animation/new.mp4" />
        </video>
        if(!this.state.images.length) return null
        return <DeviceAnimation img={this.state.images[this.state.index - 1]} />
    }
}

const DeviceAnimation = props => {
    const canvasRef = useRef(null)

    useEffect(() => {
        const canvas = canvasRef.current
        canvas.width = 1550
        canvas.height = 1080
        const context = canvas.getContext("2d")
        context.drawImage(props.img, 0, 0, 1550, 1080)
    }, [ props.img ])

    return <canvas ref={canvasRef} />
}*/

const ModalBackground = styled.div`
    z-index: 1000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.25);
`

const ModalContainer = styled.div`
    padding: 64px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 64px;
    max-width: 1024px;
    margin: 32px;

  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    -webkit-user-drag: none;
    margin: auto 0;
  }
  
    @media screen and (max-width: 1080px) {
        grid-template-columns: 1fr;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        overflow-x: hidden;
      
        img {
            max-height: 250px;
        }
    }
  
    .right {
        display: flex;
        flex-direction: column;
        
        .title {
            font-size: 40px;
            font-weight: 900;
            margin-bottom: 16px;
          background-color: #3b97d3;
          background-image: linear-gradient(45deg,#33aaff,#00ff66);
          background-size: 100%;
          background-repeat: repeat;
          -webkit-background-clip: text;
          user-select: none;
          -webkit-text-fill-color: transparent;
        }
      
        .subtitle {
            margin-bottom: 24px;
            line-height: 32px;
            font-size: 18px;
        }
      
        .features {
          padding: 24px;
          border-radius: 5px;
          background: rgb(59 151 211 / 11%);
          
          .featuresTitle {
              font-size: 18px;
              font-weight: 600;
              color: #3b97d3;
            
              i {
                  margin-right: 8px;
              }
          }
          
          ul {
              margin-top: 8px;
          }
          
          li {
              margin-left: 24px;
              line-height: 30px;
          }
        }
    }
`

class FeatureModal extends Component {
    state = { open: false }

    constructor(props) {
        super(props)

        this.open = this.open.bind(this)
    }

    open() {
        if(!this.props.disabled) this.setState({ open: true })
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    render() {
        if(!this.state.open) return null

        return <ModalBackground onClick={() => this.setState({ open: false })}>
            <ModalContainer onClick={event => event.stopPropagation()}>
                <img src={this.props.img} />
                <div className="right">
                    <p className="title">{this.props.title}</p>
                    <p className="subtitle">{this.props.subtitle}</p>
                    <div className="features">
                        <p className="featuresTitle"><i className="far fa-rocket-launch" /> Diese Funktionen erwarten dich:</p>
                        <ul>
                            {this.props.features.map((feature, index) => <li key={index}>{feature}</li>)}
                        </ul>
                    </div>
                </div>
            </ModalContainer>
        </ModalBackground>
    }
}

class FAQItem extends Component {
    state = { open: false }

    render() {
        return <StyledFAQItem>
            <div className="title" onClick={() => this.setState({ open: !this.state.open })}><i className={`far fa-${this.state.open ? "minus" : "plus"}`} /> {this.props.title}</div>
            <div className={`content ${this.state.open ? "open" : ""}`}>{this.props.children}</div>
        </StyledFAQItem>
    }
}

const StyledFAQItem = styled.div`
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
        border-bottom: none;
    }

    .title {
        font-size: 24px;
        user-select: none;
        cursor: pointer;
        font-weight: 300;
        display: flex;
        align-items: center;

        i {
            color: #3b97d3;
            width: 40px;
            flex-shrink: 0;
        }
    }

    .content {
        font-size: 18px;
        line-height: 36px;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s;
        box-sizing: border-box;
        opacity: 0;
        font-weight: 300;
        padding-left: 40px;

        &.open {
            max-height: 350px;
            padding-top: 16px;
            opacity: 1;

            @media screen and (max-width: 1400px) {
                max-height: 1024px;
            }
        }
    }
`

const FAQ = styled.div`
    padding: 128px 0;
  background: #F8F8F8;
  border-radius: 96px 0 0 0;

    h2 {
        font-size: 48px;
        font-weight: 900;
        background-color: #3b97d3;
        background-image: linear-gradient(45deg,#33aaff,#00ff66, #00ff66);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        user-select: none;
        -webkit-text-fill-color: transparent;
    }

    h3 {
        margin-bottom: 64px;
        font-size: 24px;
        font-weight: 300;
        opacity: 0.75;
        user-select: none;
        margin-top: 8px;
    }

  .container {
    width: 100%;
    padding: 0 64px;
    margin: 0 auto;
    max-width: 1280px;

    @media screen and (max-width: 1400px) {
        box-sizing: border-box;
    }

    b, i {
      color: #3b97d3;
    }

      ul {
        li {
          padding-left: 12px;
          margin-left: 20px;
        }
      }
  }
`

const Features = styled.div`
  padding: 192px 0 128px 0;
  border-radius: 0 0 96px 0;

  .container {
    width: 100%;
    padding: 0 64px;
    margin: 0 auto;
    max-width: 1280px;

    @media screen and (max-width: 1400px) {
        box-sizing: border-box;
    }
  }

  h2 {
    font-size: 48px;
    font-weight: 900;
    background-color: #3b97d3;
    background-image: linear-gradient(45deg,#33aaff,#00ff7e);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    user-select: none;
    -webkit-text-fill-color: transparent;
  }

  h3 {
    margin-bottom: 64px;
    font-size: 24px;
    font-weight: 300;
    opacity: 0.75;
    user-select: none;
    margin-top: 8px;
  }

  h2, h3 {
      text-align: center;
  }

  button {
    background: #3b97d3;
    color: white;
    outline: 0;
    border: none;
    height: 48px;
    padding: 0 24px;
    border-radius: 5px;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: #3acc8e;
    }
  }

  .features {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 80px 48px;
      margin: 80px 0;

      @media screen and (max-width: 1300px) {
          grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 850px) {
          grid-template-columns: 1fr;
      }

      .feature {
          display: flex;
          align-items: center;

          .right {
              i {
                  color: rgba(0, 0, 0, 0.4);
              }
          }

          .icon {
              flex-shrink: 0;
              width: 90px;
              height: 90px;
              display: flex;
              background: white;
              align-items: center;
              justify-content: center;
              color: #3b97d3;
              font-size: 42px;
              border-radius: 5px;
              box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
              margin-right: 24px;
          }

          .title {
              font-size: 22px;
          }

          .subtitle {
              font-weight: 300;
              font-size: 15px;
              margin-top: 8px;
          }
        
          .learn {
              cursor: pointer;
              color: #3b97d3;
              margin-top: 8px;
              font-size: 16px;
              transition: color 0.3s;
              user-select: none;

                i {
                    color: inherit;
                    font-size: 14px;
                }
            
              &:hover {
                  color: #3acc8e;
              }
          }
      }
  }
`

const StyledInput = styled.div`
    width: 100%;

    label {
        height: 16px;
        font-size: 20px;
        font-weight: 300;
        user-select: none;

        i {
            color: #3b97d3;
            margin-left: 4px;
            font-size: 10px;
            vertical-align: top;
            margin-top: 4px;
        }
    }

    input, textarea {
      font-size: 16px;
        font-family: inherit;
        width: 100%;
        height: 48px;
        margin-top: 8px;
        outline: 0;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-bottom: 0;
        box-sizing: border-box;
        padding: 0 16px;
        font-weight: 300;
        transition: all 0.3s;

        &:focus {
            border-color: #3b97d3;
        }
    }

    textarea {
        height: 128px;
        resize: none;
        padding-top: 16px;
    }
`

class Input extends Component {
    render() {
        return <StyledInput>
            <label>{this.props.label}{this.props.required && <i className="fas fa-asterisk" />}</label>
            {!this.props.textArea && <input spellCheck="false" value={this.props.value || ""} onChange={e => this.props.onChange(e.target.value)} />}
            {this.props.textArea && <textarea spellCheck="false" value={this.props.value || ""} onChange={e => this.props.onChange(e.target.value)} />}
        </StyledInput>
    }
}

const Pricing = styled.div`
  background: #20242b;
  padding-top: 64px;
  padding-bottom: 96px;

  .container {
    width: 100%;
    padding: 0 64px;
    margin: 0 auto;
    max-width: 1280px;
    display: grid;
    grid-template-columns: 1fr 1.35fr;
    grid-gap: 80px;

    @media screen and (max-width: 1400px) {
        grid-template-columns: 1fr;
      box-sizing: border-box;
    }

    .left {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;

        i {
            font-size: 90px;
            margin-bottom: 16px;
          background-color: #3b97d3;
          background-image: linear-gradient(45deg,#33aaff,#00ff66, #00ff66, #00ff66);
          background-size: 100%;
          background-repeat: repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        h3 {
            font-size: 44px;
            font-weight: 400;

            b {
                color: #3acc8e;
            }
        }

        h4 {
            font-weight: 300;
            font-size: 24px;
            margin-top: 24px;
            opacity: 0.75;
            line-height: 48px;
        }
    }

    .bottomText {
        color: white;
        text-align: right;
        grid-column-start: 1;
        grid-column-end: 3;
        font-size: 14px;
        opacity: 0.5;
    }

    .right {
        display: grid;
        grid-template-columns: 1fr 1.1fr;
        grid-gap: 24px;
        margin-top: 96px;

        @media screen and (max-width: 1400px) {
            width: 60%;
            margin: 0 auto;
        }

        @media screen and (max-width: 1080px) {
            width: 80%;
        }

        @media screen and (max-width: 900px) {
            grid-template-columns: 1fr;
            width: 100%;

            .bottomText {
                grid-column-end: 2 !important;
            }
        }

        .pricing {
            background: white;
            padding: 32px;
            border-radius: 8px;
            height: min-content;
            margin: auto 0;
            position: relative;
          
            .discount {
                position: absolute;
                background: #3acc8e;
                color: white;
                top: 0;
                transform: translateY(-50%);
                right: 8px;
                padding: 5px 10px;
                border-radius: 5px;
                font-weight: 400;
                user-select: none;
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                font-size: 18px;
              
                i {
                    font-style: normal;
                    font-size: 20px;
                    margin-right: 6px;
                }
            }
          
            .title {
              font-style: normal;
              font-weight: 900;
              background-color: #3b97d3;
              background-image: linear-gradient(45deg,#33aaff,#3bd378);
              background-size: 100%;
              background-repeat: repeat;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
                font-size: 32px;
                margin-bottom: 16px;
            }

            .price {
                font-size: 54px;
                font-weight: 900;
                display: flex;
              
                i {
                    font-size: 16px;
                }

                .amount {
                    color: #20242b;
                }

                .currency {
                    opacity: 0.5;
                    font-size: 24px;
                    margin-right: 8px;
                }

                .type {
                    font-size: 22px;
                    margin-left: 8px;
                    font-weight: 400;
                    opacity: 0.75;
                }
            }

            button {
                background: #3b97d3;
                color: white;
                outline: 0;
                border: none;
                height: 48px;
                padding: 0 24px;
                border-radius: 5px;
                font-size: 18px;
                display: flex;
                align-items: center;
                margin: 0 auto;
              user-select: none;
              cursor: pointer;
              transition: all 0.3s;

              &:hover {
                background: #3acc8e;
              }
            }

            &.office {
                padding: 48px 48px;
            }

            &.onsite {
                button {
                    background: transparent;
                    border: 2px solid #3b97d3;
                    color: #3b97d3;

                    &:hover {
                        border-color: #3acc8e;
                        color: #3acc8e;
                    }
                }
            }

            .description {
                margin-top: 16px;
                line-height: 32px;
                font-size: 18px;
                opacity: 0.75;

                i {
                    opacity: 0.5;
                }
            }

            ul {
                margin: 32px 0;

                li {
                    list-style-type: none;
                    height: 40px;
                    line-height: 40px;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.75);

                    i {
                        color: #3b97d3;
                        margin-right: 8px;
                    }
                }
            }
        }
    }
  }
`
